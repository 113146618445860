import React, {useState} from "react";
import {withRouter} from "react-router";

import Layout from "../components/layout/public";
import Icon from "../components/icon";
import ESLImage from "../images/gaming-1024x716.jpg";
import MediaKitImage from "../images/els-pdfhead-media-kit-1024x581.jpg";
import ESLMobileHeaderImage from "../images/esl-header-700X394.jpg";

const ESLPage = () => {
    const [modalCss, setModalCss] = useState("modal");
    const [pdfName, setPdfName] = useState();
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const setModalCssClass = (val, pdfName) => {
        setModalCss(val);
        setPdfName(pdfName);
        setPassword("");
        setError("");
    };

    const getMediaName = (val) => {
        switch (val) {
            case "es-all":
                return "ESL eSPORTS Marketing Kit";
            case "es-media":
                return "ESL eSPORTS Media Kit";
            default:
                return "";
        }
    };

    const downloadMedia = () => {
        if (password && password.length > 0) {
            setIsLoading(true);
            fetch(`https://dblbee-server.com/downloadKit?b=${pdfName}&p=${password}`).then((response) => response.blob()).then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = `${
                    getMediaName(pdfName)
                }.pdf`;
                a.click();
                setModalCssClass("modal", "");
                setIsLoading(false);
            });
        } else {
            setError("Password is required");
        }
    };

    return (
        <Layout showHeader={true}>
            <section className="hero is-fullheight esl-banner-background-img is-hidden-touch">
                <div className="hero-body">
                    <div className="container">
                        <h2 className="subtitle">
                            <div className="columns">
                                <div className="column" id="print1"></div>
                            </div>
                        </h2>
                    </div>
                </div>
            </section>
            <section className="hero is-medium is-hidden-desktop">
                <div className="hero-body">
                    <div className="container">
                        <h2 className="subtitle">
                            {" "}
                            <div className="columns">
                                <div className="column" id="print1">
                                    <img src={ESLMobileHeaderImage}
                                        alt="header"/>
                                </div>
                            </div>
                        </h2>
                    </div>
                </div>
            </section>

            <section className="hero is-medium">
                <div className="hero-body">
                    <div className="container">
                        <h2 className="subtitle">
                            <div className="columns">
                                <div className="column is-half" id="print1">
                                    <span className="is-size-2">
                                        The Global Phenomenon of eSPORTS​
                                    </span>
                                    <p>
                                        eSPORTS has come a long way from barely working LAN
                                                            connections in internet cafes in 2000 to the global
                                                            phenomenon it is today!
                                    </p>
                                    <dl>
                                        <li>19 years</li>
                                        <li>200 Plus Countries</li>
                                        <li>240,000,000 fans</li>
                                        <li>JUST LAST YEAR</li>
                                    </dl>
                                </div>
                                <div className="column">
                                    <figure>
                                        <img src={ESLImage}
                                            alt="gaming"/>
                                    </figure>
                                </div>
                            </div>
                            <br/>
                            <div className="columns">
                                <div className="column">
                                    <div className="column">
                                        <a href="/contact#contact">
                                            <div className="card">
                                                <div className="card-image">
                                                    <figure className="image">
                                                        <img src={MediaKitImage}
                                                            alt="media kit"/>
                                                    </figure>
                                                </div>
                                                <div className="card-content">
                                                    <div className="media">
                                                        <div className="media-content">
                                                            <p className="subtitle is-size-6">
                                                                
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="columns">
                                <div className="column">
                                    <figure className="image  is-16by9">
                                        <iframe className="has-ratio" allowFullScreen="" title="youtube Video Player" src="https://www.youtube.com/embed/Z2uzpVulXHA?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
                                    </figure>
                                </div>
                                <div className="column">
                                    <figure className="image is-16by9">
                                        <iframe className="has-ratio" allowFullScreen="" title="youtube Video Player" src="https://www.youtube.com/embed/alybFP-NVJ8?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
                                    </figure>
                                </div>
                            </div>
                            <br/>
                            <div className="columns">
                                <div className="column">
                                    <figure className="image is-16by9">
                                        <iframe className="has-ratio" allowFullScreen="" title="youtube Video Player" src="https://www.youtube.com/embed/Y65ovt5RKtw?feature=oembed&start&end&wmode=opaque&loop=0&controls=1&mute=0&rel=0&modestbranding=0"></iframe>
                                    </figure>
                                </div>
                                <div className="column">
                                    <figure className="image is-16by9">
                                        <iframe className="has-ratio" allowFullScreen="" title="youtube Video Player" src="https://www.youtube.com/embed/2CjE7EaJuXE?feature=oembed&start&end&wmode=opaque&loop=0&controls=1&mute=0&rel=0&modestbranding=0"></iframe>
                                    </figure>
                                </div>
                            </div>
                            <br/>
                            <div className="columns">
                                <div className="column">
                                    <figure className="image is-16by9">
                                        <iframe className="has-ratio" allowFullScreen="" title="youtube Video Player" src="https://www.youtube.com/embed/Fh_mvgEbXuE?feature=oembed&start&end&wmode=opaque&loop=0&controls=1&mute=0&rel=0&modestbranding=0"></iframe>
                                    </figure>
                                </div>
                            </div>
                        </h2>
                    </div>
                </div>
            </section>

            <div className={modalCss}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Download {
                            getMediaName(pdfName)
                        }</p>
                        <button className="delete" aria-label="close" type="button"
                            onClick={
                                () => setModalCssClass("modal", "")
                        }></button>
                    </header>
                    <section className="modal-card-body">
                        <div className="field">
                            <label className="label">
                                Password{" "}
                                <Icon icon={
                                        ["fas", "asterisk"]
                                    }
                                    className="has-text-danger fa-sm"/>
                            </label>
                            {
                            error && (
                                <label className="label">
                                    <Icon icon={
                                            ["fas", "exclamation-triangle"]
                                        }
                                        className="has-text-danger fa-sm"/>{" "}
                                    {error} </label>
                            )
                        }
                            <div className="control">
                                <input className="input" type="password"
                                    ref={
                                        (input) => input && input.focus()
                                    }
                                    value={password}
                                    onChange={
                                        (e) => setPassword(e.target.value)
                                    }/>
                            </div>
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        {
                        !isLoading && (
                            <button className="button is-success" type="button"
                                onClick={downloadMedia}>
                                Download
                            </button>
                        )
                    }
                        {
                        isLoading && (
                            <label className="label mr-2">
                                <Icon icon={
                                        ["fas", "spinner"]
                                    }
                                    className="fa-sm fa-pulse"/>{" "}
                                Downloading...
                            </label>
                        )
                    }
                        <button className="button" type="button"
                            onClick={
                                () => setModalCssClass("modal", "")
                        }>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(ESLPage);
