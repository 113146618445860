import React from "react";
import { withRouter } from "react-router";

import Layout from "../components/layout/public";
import Icon from "../components/icon";
import ContactUs from "../components/contact";

const ContactPage = () => {
  return (
    <Layout showHeader={true}>
      <section className="hero is-fullheight contact-banner-background-img">
        <div className="hero-body">
          <div className="container has-text-white">
            <h1 className="title has-text-weight-bold">
              HIRE US AND SEE POSITIVE RESULTS
            </h1>

            <h2 className="subtitle">
              We believe that a business with a great marketing team can make an
              impact on the world.
              <br />
              Take the first step: contact us, and together, we will reach your
              company’s goals.
            </h2>
            <a
              className="is-size-5 has-text-success has-text-weight-bold"
              href="#contact"
            >
              Reach Out To Us <Icon icon="arrow-right" />
            </a>
          </div>
        </div>
      </section>
      <section className="hero">
        <div className="hero-body" id="contact">
          <div className="container">
            <div className="columns">
              <div className="column is-one-third">
                <div className="card">
                  <div className="card-image has-text-centered py-2">
                    <Icon icon={["fas", "home"]} className="fa-6x" />
                  </div>
                  <div className="card-content has-text-centered">
                    <div className="subtitle">WHERE ARE WE?</div>
                    <div className="media">
                      <div className="media-content has-text-centered">
                        <p className="subtitle is-size-6">
                          Our company resides in beautiful sunny Florida.
                        </p>
                        <p className="is-size-4 has-text-weight-bold">
                          Tampa, Florida
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="card">
                  <div className="card-image has-text-centered py-2">
                    <Icon icon={["fas", "phone"]} className="fa-6x" />
                  </div>

                  <div className="card-content has-text-centered">
                    <div className="subtitle">Call US</div>
                    <div className="media">
                      <div className="media-content has-text-centered">
                        <p className="subtitle is-size-6">
                          Don't hesitate to pick up the phone!
                        </p>
                        <p className="is-size-4 has-text-weight-bold">
                          +1 727 238 4539
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="card">
                  <div className="card-image has-text-centered py-2">
                    <Icon icon={["fas", "envelope"]} className="fa-6x" />
                  </div>
                  <div className="card-content has-text-centered">
                    <div className="subtitle">CONTACT US</div>
                    <div className="media">
                      <div className="media-content has-text-centered">
                        <p className="subtitle is-size-6">
                          Or if you are more the emailing type feel free!
                        </p>
                        <p className="is-size-4 has-text-weight-bold">
                          <a href="mailto:info@finesseunique.com">
                            info@finesseunique.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section className="hero">
        <div className="hero-body">
          <div className="container">
              <div className="subtitle">Send Us a Message</div>
              <ContactUs />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default withRouter(ContactPage);
