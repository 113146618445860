import React from 'react'
import { Link } from 'react-router-dom'

import Icon from './icon'

const Footer = () => (
  <section className="hero is-small is-black">
    <div className="hero-body">
      <footer className="footer has-background-black">
        <div className="content">
          <div className="columns">
            <div className="column">
              <div className="columns">
                <div className="column">
                  <dl className="alt">
                    <dt>Location</dt>
                    <dd>Tampa, Florida</dd>
                    <dt>Phone</dt>
                    <dd>(813) 461-4080</dd>
                    <dt>Email</dt>
                    <dd>
                      <a className="has-text-white" href="mailto:info@finesseunique.com">
                        info@finesseunique.com
                      </a>
                    </dd>
                    <dt> </dt>
                    <dd>
                      <Link to="/contact" className="button is-primary has-text-white">
                        Contact Us
                      </Link>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <a href="https://www.facebook.com/OfficialFinesseUnique" className="is-size-3 has-text-grey" target="_blank" rel="noopener noreferrer">
            <Icon icon={['fab', 'facebook-square']} />
          </a>
          <a href="https://twitter.com/Finesse_Unique" className="is-size-3 has-text-grey pl-4" target="_blank" rel="noopener noreferrer">
            <Icon icon={['fab', 'twitter-square']} />
          </a>
          <a href="https://www.instagram.com/officialfinesseunique" className="is-size-3 has-text-grey pl-4" target="_blank" rel="noopener noreferrer">
            <Icon icon={['fab', 'instagram-square']} />
          </a>
          <a href="https://www.linkedin.com/company/finesseunique/" className="is-size-3 has-text-grey pl-4" target="_blank" rel="noopener noreferrer">
            <Icon icon={['fab', 'linkedin']} />
          </a>
        </div>
      </footer>
    </div>
  </section>
)

export default Footer
