import React from 'react'
import { withRouter } from 'react-router'

import Layout from '../components/layout/public'
import SurvivorPreReg from '../components/survivor-prereg'
import SurvivorMobileHeaderImage from '../images/survivor-banner-desktop-zinio.jpeg'

const SurvivorLandingPage = () => {
  return (
    <Layout showHeader={true}>
      <br />
      <section className="hero is-small">
        <div className="hero-body">
          <div className="container">
            <h2 className="subtitle">
              <div className="columns">
                <div className="column" id="print1">
                  <img src={SurvivorMobileHeaderImage} alt="header" />
                </div>
              </div>
            </h2>
          </div>
        </div>
      </section>
      <section className="hero is-small">
        <div className="hero-body">
          <div className="container">
            <div className="subtitle">Get notified when the print version is available.</div>
            <SurvivorPreReg />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withRouter(SurvivorLandingPage)
