import React from "react";
import { withRouter } from "react-router";

import Layout from "../components/layout/public";
import NasImage from "../images/nas-1024x672.jpg";
import MediaKitImage from "../images/media-deck-NASCAR-1-233x300.jpg";
import NascarMobileHeaderImage from "../images/nascar-header2-1-700X394.jpg";
import VideoSrc from "../images/JJ-Yeley-video.mp4";

const NascarPage = () => (
  <Layout showHeader={true}>
    <section className="hero is-fullheight nascar-banner-background-img is-hidden-touch">
      <div className="hero-body">
        <div className="container">
          <h2 className="subtitle">
            {" "}
            <div className="columns">
              <div className="column" id="print1"></div>
            </div>
          </h2>
        </div>
      </div>
    </section>
    <section className="hero is-medium is-hidden-desktop">
      <div className="hero-body">
        <div className="container">
          <h2 className="subtitle">
            {" "}
            <div className="columns">
              <div className="column" id="print1">
                <img src={NascarMobileHeaderImage} alt="header" />
              </div>
            </div>
          </h2>
        </div>
      </div>
    </section>

    <section className="hero is-medium">
      <div className="hero-body">
        <div className="container">
          <h2 className="subtitle">
            <div className="columns">
              <div className="column is-half" id="print1">
                <span className="is-size-2">Let's Go Racin'!</span>
                <p>We have opportunities on every stage of NASCAR</p>
                <dl>
                  <li>Cup Series </li>
                  <li>Xfinity Series</li>
                  <li>Gander Outdoor Truck Series</li>
                  <li>ARCA</li>
                  <li>K & N East and West Series</li>{" "}
                </dl>
              </div>
              <div className="column">
                <figure>
                  <img src={NasImage} alt="nascar team" />
                </figure>
              </div>
            </div>
            <br />
            <div className="columns">
              <div className="column">
                <div className="has-text-centered">
                  <video width="35%" height="35%" controls>
                    <source src={VideoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div className="column">
                <div className="columns">
                  <div className="column is-one-third">
                    <a href="/contact#contact">
                      <div className="card">
                        <div className="card-image">
                          <figure className="image is-4by5">
                            <img src={MediaKitImage} alt="media kit" />
                          </figure>
                        </div>
                        <div className="card-content">
                          <div className="media">
                            <div className="media-content">
                              <p className="subtitle is-size-6">
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </h2>
        </div>
      </div>
    </section>
  </Layout>
);

export default withRouter(NascarPage);
