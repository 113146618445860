import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Icon from "./icon";

const CPMCalculator = () => {
  const [allFieldsError, setAllFieldsError] = useState();

  const validate = Yup.object({
    campaignCost: Yup.number()
      .typeError("Number requied")
      .positive("Positive number required"),
    cpmCost: Yup.number()
      .typeError("Number requied")
      .positive("Positive number required"),
    impressionCount: Yup.number()
      .typeError("Number requied")
      .positive("Positive number required"),
  });

  const formik = useFormik({
    initialValues: {
      campaignCost: "",
      cpmCost: "",
      impressionCount: "",
    },
    validationSchema: validate,
    onSubmit: (values) => {
      let campaignCost = values.campaignCost;
      let cpmCost = values.cpmCost;
      let impressionCount = values.impressionCount;
      if (campaignCost && cpmCost && impressionCount) {
        setAllFieldsError("Only two fields can be filled out");
        return;
      }
      // ad impressions
      if (campaignCost && cpmCost) {
        values.impressionCount = Math.round((campaignCost * 1000) / cpmCost);
      }
      // cpm cost
      if (campaignCost && impressionCount) {
        values.cpmCost = ((campaignCost * 1000) / impressionCount).toFixed(2);
      }
      // cpm cost
      if (cpmCost && impressionCount) {
        values.campaignCost = ((cpmCost * impressionCount) / 1000).toFixed(2);
      }

      setAllFieldsError("");
      formik.resetForm({ values });
    },
  });

  return (
    <div className="card has-background-light">
      <form onSubmit={formik.handleSubmit}>
        <div className="card-content">
          <p className="title is-4">CPM Calculator</p>
          <p className="subtitle is-5">
            Fillout any two fields to calculate the other field
          </p>
          <div className="field">
            {formik.touched.campaignCost && formik.errors.campaignCost ? (
              <label className="label has-text-danger">
                Campaign Cost{" "}
                <Icon
                  icon={["fas", "exclamation-triangle"]}
                  className="fa-sm"
                />{" "}
                {formik.errors.campaignCost}
              </label>
            ) : (
              <label className="label">Campaign Cost</label>
            )}
            <div className="control has-icons-left">
              <input
                name="campaignCost"
                className="input"
                type="text"
                placeholder="1000.00"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.campaignCost}
              />
              <span className="icon is-small is-left">
                <Icon icon={["fas", "dollar-sign"]} className="fa-sm" />
              </span>
            </div>
          </div>

          <div className="field">
            {formik.touched.cpmCost && formik.errors.cpmCost ? (
              <label className="label has-text-danger">
                CPM Cost{" "}
                <Icon
                  icon={["fas", "exclamation-triangle"]}
                  className="fa-sm"
                />{" "}
                {formik.errors.cpmCost}
              </label>
            ) : (
              <label className="label">CPM Cost</label>
            )}
            <div className="control has-icons-left">
              <input
                name="cpmCost"
                className="input"
                type="text"
                placeholder="100.00"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cpmCost}
              />
              <span className="icon is-small is-left">
                <Icon icon={["fas", "dollar-sign"]} className="fa-sm" />
              </span>
            </div>
          </div>

          <div className="field">
            {formik.touched.impressionCount && formik.errors.impressionCount ? (
              <label className="label has-text-danger">
                Ad Impressions{" "}
                <Icon
                  icon={["fas", "exclamation-triangle"]}
                  className="fa-sm"
                />{" "}
                {formik.errors.impressionCount}
              </label>
            ) : (
              <label className="label">Ad Impressions</label>
            )}
            <div className="control has-icons-left">
              <input
                name="impressionCount"
                className="input"
                type="text"
                placeholder="1000"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.impressionCount}
              />
              <span className="icon is-small is-left">
                <Icon icon={["fas", "users"]} className="fa-sm" />
              </span>
            </div>
          </div>
          <div className="field">
            <div className="columns">
              <div className="column is-2">
                {" "}
                <button className="button is-success" type="submit">
                  Calculate
                </button>
              </div>

              {allFieldsError && (
                <div className="column is-6">
                  <label className="label has-text-danger ml-2">
                    <Icon
                      icon={["fas", "exclamation-triangle"]}
                      className="fa-sm"
                    />{" "}
                    {allFieldsError}
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CPMCalculator;
