import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = withRouter(({ history, location }) => {
  useEffect(() => {
    const unlisten = history.listen((newLocation) => {
      if (newLocation.pathname !== location.pathname) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, [history, location]);

  return null;
});

export default ScrollToTop;
