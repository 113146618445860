import React, { useState } from "react";

import Icon from "./icon";

const Profile = ({
  image,
  name,
  title,
  facebook,
  twitter,
  instagram,
  linkedin,
}) => {
  const [fadeCssClass, setFadeCssClass] = useState("hide-me");
  const setHoveringCss = (val) => {
    setFadeCssClass(val ? "card-footer fade-in" : "card-footer fade-out");
  };

  return (
    <div
      className="card has-text-centered profile-card"
      onMouseEnter={() => setHoveringCss(true)}
      onMouseLeave={() => setHoveringCss(false)}
    >
      <div className="card-image has-text-centered">
        <figure className="image is-128x128 is-inline-block">
          <img src={image} alt="profile" />
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-4 is-size-6">{name}</p>
            <p className="subtitle is-6">{title}</p>
          </div>
        </div>
        <footer className={fadeCssClass}>
          {facebook !== "#void" && (
            <a href={facebook} className="card-footer-item">
              <Icon icon={["fab", "facebook"]} />
            </a>
          )}
          {twitter !== "#void" && (
            <a href={twitter} className="card-footer-item">
              <Icon icon={["fab", "twitter"]} />
            </a>
          )}
          {instagram !== "#void" && (
            <a href={instagram} className="card-footer-item">
              <Icon icon={["fab", "instagram"]} />
            </a>
          )}
          {linkedin !== "#void" && (
            <a href={linkedin} className="card-footer-item">
              <Icon icon={["fab", "linkedin"]} />
            </a>
          )}
        </footer>
      </div>
    </div>
  );
};

export default Profile;
