import React, { useState } from "react";
import FadeIn from "react-fade-in";
import Typewriter from "typewriter-effect";

const Banner = ({ messages }) => {
  const [showFadeIn, setShowFadeIn] = useState(false);
  const [messageNum, setMessageNum] = useState(0);

  return (
    <div className="container">
      <h1 className="title is-size-1">
        <Typewriter
          options={{
            cursor: "",
            autoStart: true,
            loop: true,
            wrapperClassName: "largeText",
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString(messages[0].title)
              .callFunction(() => {
                setMessageNum(0);
                setShowFadeIn(true);
              })
              .pauseFor(2500)
              .deleteAll()
              .callFunction(() => {
                setShowFadeIn(false);
              })
              .typeString(messages[1].title)
              .callFunction(() => {
                setMessageNum(1);
                setShowFadeIn(true);
              })
              .pauseFor(2500)
              .deleteAll()
              .callFunction(() => {
                setShowFadeIn(false);
              })
              .start();
          }}
        />
      </h1>
      <h2 className="subtitle fadeInContainer is-size-2">
        {showFadeIn && (
          <FadeIn>
            {messages[messageNum].message}
          </FadeIn>
        )}
      </h2>
    </div>
  );
};

export default Banner;
