import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import Layout from "../components/layout/public";
import Banner from "../components/banner";
import SurvivorImage from "../images/SurvivorUniversalLogo.JPG.png";
import NascarImage from "../images/nascar.png";
import ESLImage from "../images/ESL_Logo_Stacked_RGB_POS.jpg";
import Icon from "../components/icon";

const messages = [];
messages.push(
  {
    title: "Stellar Projects",
    message:
      "Working with the right clients on the right projects is the key to our success.",
  },
  {
    title: "Amazing Clients",
    message:
      "Working with the right clients on the right projects is the key to our success.",
  }
);
const ProjectsPage = () => (
  <Layout showHeader={true}>
    <section className="hero is-fullheight is-black">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-3 has-text-grey">About Us...</h1>
          <h2 className="subtitle">
            <br />
            <Banner messages={messages} />
            <a href="#print1">
              Current Projects <Icon icon="arrow-right" />
            </a>
          </h2>
        </div>
      </div>
    </section>

    <section className="hero is-medium">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-half" id="print1">
              <div className="column">
                <figure>
                  <Link to="/survivor">
                    <img src={SurvivorImage} alt="survivor at 40" />
                  </Link>
                </figure>
              </div>
            </div>
            <div className="column is-half">
              <figure>
                <Link to="/esl">
                  <img src={ESLImage} alt="ESL" />
                </Link>
              </figure>
            </div>
          </div>
          <br />
          <div className="columns">
            <div className="column is-offset-one-quarter">
              <figure>
                <Link to="/nascar">
                  <img src={NascarImage} alt="nascar" />
                </Link>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default withRouter(ProjectsPage);
