import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import Layout from "../components/layout/public";
import Profile from "../components/profile";
import TeamMobileHeaderImage from "../images/nascar-header-team-700x394.jpg";
import Icon from "../components/icon";
import JohnnyImage from "../images/Johnny.png";
import RandyImage from "../images/randy.png";
import StacyImage from "../images/stacy-2.png";
import AmberImage from "../images/amber.png";
import ErinImage from "../images/erin.png";
import RayImage from "../images/ray-1.png";

const TeamPage = () => (
  <Layout showHeader={true}>
    <section className="hero is-fullheight team-banner-background-img is-hidden-touch">
      <div className="hero-body">
        <div className="container has-text-white">
          <h1 className="title is-3 has-text-white has-text-weight-bold">
            Culture
          </h1>
          <h2 className="subtitle is-size-1 has-text-white has-text-weight-bold">
            We have one goal: TO BUILD AND GROW YOUR BRAND.
          </h2>

          <p className="is-size-3">
            Our diverse team of creative communication strategists, brand
            storytellers, designers, creators, planners, innovators, writers,
            and marketers will help you every step of the way.
          </p>
          <Link
            className="is-size-5 has-text-success has-text-weight-bold"
            to="/jobs"
          >
            Current Job Openings <Icon icon="arrow-right" />
          </Link>
        </div>
      </div>
    </section>
    <section className="hero is-medium is-hidden-desktop">
      <div className="hero-body">
        <div className="container">
          <img src={TeamMobileHeaderImage} alt="header" />
          <h1 className="title is-3 has-text-weight-bold">Culture</h1>
          <h2 className="subtitle is-size-1 has-text-weight-bold">
            We have one goal: TO BUILD AND GROW YOUR BRAND.
          </h2>

          <p className="is-size-3">
            Our diverse team of creative communication strategists, brand
            storytellers, designers, creators, planners, innovators, writers,
            and marketers will help you every step of the way.
          </p>
        </div>
      </div>
    </section>

    <section className="hero is-medium">
      <div className="hero-body">
        <div className="container">
          <h2 className="subtitle">
            <div className="columns">
              <div className="column">
                <Profile
                  image={JohnnyImage}
                  name="Johnny 'THE AMBASSADOR OF FUN' Wilson"
                  title="Senior Cross-Functional Leader"
                  facebook="#void"
                  twitter="#void"
                  instagram="#void"
                  linkedin="#void"
                />
              </div>
              <div className="column">
                <Profile
                  image={RandyImage}
                  name="Randy 'MR. ALL AMERICAN' Mathews"
                  title="Communications Director"
                  facebook="#void"
                  twitter="#void"
                  instagram="#void"
                  linkedin="#void"
                />
              </div>
              <div className="column">
                <Profile
                  image={StacyImage}
                  name="Stacy 'THE CREATIVE UNICORN' Kovatch"
                  title="Creative Director"
                  facebook="#void"
                  twitter="#void"
                  instagram="#void"
                  linkedin="#void"
                />
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <Profile
                  image={RayImage}
                  name="Coach Ray 'Mr. Baseball' Baker"
                  title="Project Director"
                  facebook="#void"
                  twitter="#void"
                  instagram="#void"
                  linkedin="#void"
                />
              </div>

              <div className="column">
                <Profile
                  image={ErinImage}
                  name="Erin 'RAE OF SUNSHINE' Kelley"
                  title="Project Manager"
                  facebook="#void"
                  twitter="#void"
                  instagram="#void"
                  linkedin="#void"
                />
              </div>
              <div className="column">
                <Profile
                  image={AmberImage}
                  name="Amber Marie 'SHE'S GOT MOXIE' Kelley"
                  title="Operations Director and Chief Idea Wizard"
                  facebook="#void"
                  twitter="#void"
                  instagram="#void"
                  linkedin="#void"
                />
              </div>
            </div>

          </h2>
        </div>
      </div>
    </section>
  </Layout>
);

export default withRouter(TeamPage);
