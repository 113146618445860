import React, { useState } from "react";
import { withRouter } from "react-router";

import Layout from "../components/layout/public";
import ConnectedImg from "../images/ConnectedTV-1024x576.jpg";
import OTTChannelsImg from "../images/ott-channels-2-1024x576.png";
import DepositImg from "../images/Depositphotos_16348215_l-2015.jpg";
import OTTDevicesImg from "../images/ott-devices-white.png";
import ScreenshotImg from "../images/Screen-Shot-2017-09-12-at-8.41.29-PM-768x442.png";
import Icon from "../components/icon";
import CPMCalculator from "../components/cpm-calc";
import ContactUs from "../components/contact";

const style1 = {
  padding: "56.25% 0 0 0",
  position: "relative",
};

const style2 = {
  height: "100%",
  left: "0",
  position: "absolute",
  top: "0",
  width: "100%",
};

const style3 = {
  height: "100%",
  position: "relative",
  width: "100%",
};

const style4 = {
  height: "100%",
  left: "0",
  opacity: "0",
  overflow: "hidden",
  position: "absolute",
  top: "0",
  transition: "opacity 200ms",
  width: "100%",
};

const style5 = {
  filter: "blur(5px)",
  height: "100%",
  objectFit: "contain",
  width: "100%",
};

const ServicesPage = () => {
  const [modalCss, setModalCss] = useState("modal");

  const setModalCssClass = () => {
    let val = modalCss;
    if (modalCss === "modal") {
      val = "modal is-active";
    } else {
      val = "modal";
    }
    setModalCss(val);
  };

  return (
    <Layout showHeader={true}>
      <section className="hero is-fullheight digital-banner-background-img">
        <div className="hero-body">
          <div className="container">
            <h1 className="title is-size-1 has-text-centered has-text-white">
              Connected TV/OTT
            </h1>
            <h2 className="subtitle">
              <div className="columns">
                <div className="column has-text-centered has-text-white">
                  <p>
                    Leverage Your TV & Video Ad Budgets With <br />A Combination
                    Of Hyper Local Addressable <br /> Geofencing & Connected
                    TV/OTT Advertising <br /> With Up To 96% View Rates
                  </p>
                  <br />
                  <div className="columns">
                    <div className="column has-text-centered">
                      <button
                        onClick={() => setModalCssClass()}
                        className="button is-primary"
                      >
                        Get Pricing Today
                      </button>
                    </div>
                  </div>

                  <a href="#cpm" className="has-text-white">
                    Calculate CPM <Icon icon="arrow-right" />
                  </a>
                </div>
              </div>
            </h2>
          </div>
        </div>
      </section>
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            <h1 className="title has-text-centered has-text-black">
              How OTT Advertising + Household Geofencing Improves Audience
              Targeting
            </h1>
            <h2 className="subtitle">
              <div className="columns">
                <div className="column is-half">
                  <div className="wistia_responsive_padding" style={style1}>
                    <div className="wistia_responsive_wrapper" style={style2}>
                      <div
                        className="wistia_embed wistia_async_2qckaj0mm8 videoFoam=true"
                        style={style3}
                      >
                        <div className="wistia_swatch" style={style4}>
                          <img
                            src="https://fast.wistia.com/embed/medias/2qckaj0mm8/swatch"
                            style={style5}
                            alt=""
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-half">
                  <h1 className="block has-text-weight-semibold">
                    Our Geo fencing and addressable (household) geofencing
                    technology combined with OTT Advertising can give
                    advertisers the competitive edge they’ve been looking for
                    and that none of their competitors are using. See mobile
                    location based advertising in action:{" "}
                  </h1>
                  <div className="block">
                    <Icon icon={["fas", "check-square"]} /> Personal Injury
                    Lawyers wanting to reach accident victims at ER Centers,
                    Hospitals, and Car Repair Centers
                  </div>
                  <div className="block">
                    <Icon icon={["fas", "check-square"]} /> Car dealership who
                    desires to target their competitor dealerships and measure
                    foot traffic back to their showrooms.
                  </div>
                  <div className="block">
                    <Icon icon={["fas", "check-square"]} /> Retailer or
                    franchise wants to run a customer loyalty program, so they
                    build geo fencing around their own stores to drive repeat
                    customers.
                  </div>
                  <div className="block">
                    <Icon icon={["fas", "check-square"]} /> Digital Marketing
                    Agency wants to white label and resell and bring OTT
                    advertising and addressable geofencing solutions to its
                    clients.
                  </div>
                </div>
              </div>
            </h2>
          </div>
        </div>
      </section>
      <section className="hero is-light">
        <div className="hero-body">
          <div className="container">
            <h2 className="subtitle">
              <div className="columns">
                <div className="column is-half">
                  <h1 className="block has-text-weight-semibold">
                    What Is OTT Advertising – Combine Precision Of Geofencing
                  </h1>
                  <div className="block is-size-5">
                    For some time, advertisers have used geofencing marketing in
                    a localized way, targeting consumers in precise areas. Many
                    of those same midsize companies didn’t have the budgets to
                    advertise on TV Stations ranging from TBS to TNT. Now, it’s
                    possible for those with and without TV budgets to advertise
                    through Connected TV & Over The Top Devices.
                  </div>
                  <div className="block is-size-5">
                    There are now an estimated 63 million “cord-cutters” and
                    “cord-nevers” in the US only reachable by Connected TV and
                    digital channels. Connected TV advertising (CTV) & OTT
                    Advertising provides enterprises with a programmatic
                    advertising platform for engaging digital TV streamers with
                    the level of precision you expect from all your campaigns:
                    in-market audiences, demographics, offline behaviors and
                    geofencing & household geofencing.
                  </div>
                  <h1 className="block has-text-weight-semibold">
                    Over 1500 Demographic & Psychographic Variables
                  </h1>
                  <div className="block is-size-5">
                    When we say hyper local precision, we mean it. When you
                    combine CTV connected tv Advertising, with geofencing,
                    layered in with over 1,300 demographic and psychographic
                    variables that our team can pull from to target your ideal
                    consumer, we are ensuring advertisers are reaching those who
                    are most likely to become a customer.{" "}
                    <strong>
                      And the best part…..we can still measure foot traffic to
                      your stores for those who have a retail and brick/mortar
                      presence.
                    </strong>
                  </div>
                </div>
                <div className="column is-half">
                  <img
                    src={ConnectedImg}
                    className="image"
                    alt="connected"
                  ></img>
                </div>
              </div>
            </h2>
          </div>
        </div>
      </section>
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            <h2 className="subtitle">
              <div className="columns">
                <div className="column is-half">
                  <img
                    src={OTTChannelsImg}
                    className="image"
                    alt="ott channels"
                  ></img>
                </div>
                <div className="column is-half">
                  <h1 className="block has-text-weight-semibold">
                    Benefits Of Connected TV/OTT Advertising
                  </h1>
                  <div className="block is-size-5">
                    With Our OTT/CTV TV Advertising solution, advertisers have
                    access to the most advanced solution in the market. Powered
                    by three distinct methods of audience targeting, getting the
                    right message in front of the right person at the right time
                    has never been more effective.
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} /> Target physical
                    addresses using GPS data to serve OTT/CTV ads with unmatched
                    precision and scale.
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} /> Bring the precision
                    of digital targeting to what is OTT advertising with the
                    ability to target users based on keywords, context, and
                    other online behaviors.
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} /> Take advantage of
                    numerous combinations of demographic variables to target
                    your ideal audience.
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} /> Up To 96% View Rates
                    Of Commercial On Connected TV Advertising Devices
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} /> Connected TV
                    Watchers Tend To Be More Engaged W/ Content
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} /> Can Still Measure
                    Foot Traffic To Your Retail/Brick & Mortar Location
                  </div>
                </div>
              </div>
            </h2>
          </div>
        </div>
      </section>
      <section className="hero is-light">
        <div className="hero-body">
          <div className="container">
            <h2 className="subtitle">
              <div className="columns">
                <div className="column is-half">
                  <h1 className="block has-text-weight-semibold">
                    Addressable Geofencing + Programmatic TV = Big Wins For
                    Enterprises
                  </h1>
                  <div className="block is-size-5">
                    Do you have a list of customers or business addresses?
                    Addressable Geo-Fencing can be used both as a stand-alone
                    tactic and to improve the results of addressable
                    programmatic TV campaigns, direct mail campaigns, and other
                    marketing efforts that target specific households. By
                    extending the reach, improving the frequency, and providing
                    foot traffic attribution through conversion zone tracking,
                    Addressable Geo-Fencing makes all household and business
                    address targeting efforts more effective through our{" "}
                    <strong>
                      automated platline geofencing drawing capabilities.
                    </strong>
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} />{" "}
                    <strong>Highly Precise</strong> – Targeting is based off of
                    plat line data from property tax and public land surveying
                    information to maximize precision of addresses being
                    targeted. <strong>Up To 90% Match Rate</strong>
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} />{" "}
                    <strong>Highly Scalable</strong> – Up to 1 million physical
                    household and business addresses can be targeted per
                    campaign.
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} />{" "}
                    <strong>Provides Foot Traffic Attribution</strong> –
                    Conversion Zones can be used with Addressable Geo-Fencing
                    campaigns to track uplift in foot traffic to the
                    advertiser’s location.
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} />{" "}
                    <strong>Multiple Creative Formats Available</strong> –
                    Advertisers can utilize static ads, video ads, and OTT
                    marketing (i.e Roku) Ad campaigns with addressable
                    geofencing.
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} />{" "}
                    <strong>More Precise Than IP Targeting</strong> –
                    Addressable geofencing is even more precise than IP Address
                    Targeting, yielding a 90% match rate.
                  </div>
                </div>
                <div className="column is-half">
                  <img src={DepositImg} className="image" alt="deposit"></img>
                </div>
              </div>
            </h2>
          </div>
        </div>
      </section>
      <section className="hero is-black">
        <div className="hero-body">
          <div className="container">
            <h2 className="subtitle">
              <div className="columns">
                <div className="column is-half">
                  <img
                    src={OTTDevicesImg}
                    className="image"
                    alt="ott devices"
                  ></img>
                </div>
                <div className="column is-half">
                  <h1 className="block has-text-weight-semibold">
                    Other Key Connected TV/OTT Benefits
                  </h1>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} /> Ability to target at
                    the screen size
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} /> Non-skippable,
                    immersive content format that allows advertisers to engage
                    with a unique audience that is committed to the content they
                    are consuming
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} /> Reach a unique group
                    of video consumers that advertisers can’t target with
                    traditional TV commercials
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} /> Access to inventory
                    for both short and long-form content on the big screen and
                    other connected devices
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} /> Allows advertisers
                    to couple the impact of TV w/ the precision of digital
                  </div>
                  <div className="block is-size-5">
                    <Icon icon={["fas", "check-square"]} /> Access and buy
                    premium OTT/CTV inventory on the same centralized platform
                    as your display, mobile, and video campaigns
                  </div>
                </div>
              </div>
            </h2>
          </div>
        </div>
      </section>
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            <h1 className="title has-text-centered">
              100’S Of Publishers To Display Your “TV” Commercials On
            </h1>
            <h2 className="subtitle">
              <div className="columns">
                <div className="column has-text-centered">
                  <p>
                    120 Sports | A&E | Select ABC Affiliates | Accent Health |
                    Anthem Sports & Entertainment | AOL | AT&T/DirectTV Now! |
                    BigStar.TV | Bravo | Select CBS Affiliates | CNBC | CNN |
                    Comcast | Cooking Channel | Crackle | Digital Media Rights |
                    Discover Channel | DiscoveryGo | Dish | DIY | DramaFever |
                    E! | ESPN | E.W. Scripps Newsy | Film on TV, Inc. |
                    Flickstream | Flipps Media | Food Network | Select Fox
                    Affiliates | Fox Networks Group | FX Now | Fox News | Fox
                    Sports | Funimation | Future Today Inc. | Gold Line
                    Temangement | Haystack.tv | HGTV | History Channel | Looke
                    Digital | MAZ | Select NBC Affiliates | Lifetime | MTV | NFL
                    Network | One by AOl Ad Network | Oxygen | Plex | PlutoTV |
                    Resignation Media, LLC | Screen Media Ventures LLC | Scripps
                    Network, LLC | Sling TV | Smart Roll Media, LLC | TBS |
                    Time, Inc. | The Weather Channel | TNT | Toon Googles |
                    Travel Channel | TubiTV | Turner Digital | USA | Vevo |
                    Vidillion | WeatherNation | Xumo | Yahoo!
                  </p>
                </div>
              </div>
            </h2>
            <h1 className="title has-text-centered">Plenty Of Devices</h1>
            <h2 className="subtitle">
              <div className="columns">
                <div className="column has-text-centered">
                  <p>
                    Amazon | Apple | Asus | Google | Hisense | JadooTV | LG |
                    Microsoft | MiniX | NVIDIA | Roku | Samsung | Sony | Xiamoni
                  </p>
                </div>
              </div>
            </h2>
            <h1 className="title has-text-centered">Many Exchanges</h1>
            <h2 className="subtitle">
              <div className="columns">
                <div className="column has-text-centered">
                  <p>
                    AdapTV | Beachfront | Freewheel | Rubicon | SpotX | 75+ PMP
                    deals
                  </p>
                </div>
              </div>
            </h2>
          </div>
        </div>
      </section>
      <section className="hero is-light">
        <div className="hero-body">
          <div className="container">
            <h2 className="subtitle">
              <div className="columns">
                <div className="column is-half">
                  <h1 className="block has-text-weight-semibold">
                    OTT/CTV Advertising Reporting
                  </h1>
                  <div className="block is-size-5">
                    What is OTT Advertising cover? Tracking online conversions
                    (form submissions and sales) is one thing. But measuring
                    offline conversions is something that couldn’t be done
                    before until now. We are able to track conversions both
                    online, as well as offline conversions (i.e. those who came
                    back to your restaurant, retail store, desired location).
                  </div>

                  <div className="block is-size-5">
                    If you are leveraging our location based technology to
                    directly communicate and reach your desired audience,
                    contact us on how we’d develop a granular campaign tailored
                    to your business and organizational goals. Learn more about
                    our conversion zone tracking capabilities.
                  </div>
                </div>
                <div className="column is-half">
                  <img
                    src={ScreenshotImg}
                    className="image"
                    alt="screeshot"
                  ></img>
                </div>
              </div>
            </h2>
          </div>
        </div>
      </section>
      <section className="hero" id="cpm">
        <div className="hero-body">
          <div className="container">
            <h2 className="subtitle">
              <div className="columns">
                <div className="column is-half is-offset-one-quarter">
                  <CPMCalculator />
                </div>
              </div>
            </h2>
          </div>
        </div>
      </section>

      <div className={modalCss}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">OTT Marketing Request</p>
            <button
              className="delete"
              aria-label="close"
              type="button"
              onClick={() => setModalCssClass()}
            ></button>
          </header>
          <section className="modal-card-body">
            <ContactUs cancelCallback={setModalCssClass} />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(ServicesPage);
