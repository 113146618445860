import React, { useState } from "react";
import { Link } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";

import HeaderImage from "../images/header-logo.png";
import MenuItem from "./menu-item";

const Header = ({ location }) => {
  const defaultConsent = Cookies.get("FUConsent");
  const [toggleState, setToggleState] = useState("");
  const [consented, setConsented] = useState(!defaultConsent ? false : true);

  const toggle = () => {
    if (toggleState === "") {
      setToggleState("is-active");
    } else {
      setToggleState("");
    }
  };

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <img src={HeaderImage} alt="header" />
        </Link>
        <div
          className={`navbar-burger burger ${toggleState}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="topRightNavBar"
          onClick={toggle}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      <div id="topRightNavBar" className={`navbar-menu ${toggleState}`}>
        <div className="navbar-start">
          <div className="navbar-item has-dropdown is-hoverable">
            <MenuItem className="navbar-link" to="/projects" onClick={null}>
              Projects
            </MenuItem>
            <div className="navbar-dropdown">
              <Link className="navbar-item" to="/survivor">
                Survivor at 40
              </Link>
              <Link className="navbar-item" to="/esl">
                ESL - eSPORTS
              </Link>
              <Link className="navbar-item" to="/nascar">
                NASCAR
              </Link>
            </div>
          </div>
          <Link className="navbar-item" to="/digital">
            Digital Marketing
          </Link>
          <Link className="navbar-item" to="/team">
            Team
          </Link>
          <Link className="navbar-item" to="/jobs">
            Join
          </Link>
          {/* <Link className="navbar-item" to="/news">
            Blog
          </Link> */}
          <Link className="navbar-item" to="/contact">
            Contact
          </Link>
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons"></div>
          </div>
        </div>
      </div>
      {!consented && (
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="FUConsent"
          cookieValue={true}
          style={{ background: "#eee", color: "#666" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
          acceptOnScroll={true}
          debug={true}
          onAccept={() => {
            setConsented(true);
            Cookies.set("FUConsent", true);
          }}
        >
          By using this site, you agree to the{" "}
          <Link to="privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link to="terms" target="_blank" rel="noopener noreferrer">
            Terms of Use
          </Link>
          .
        </CookieConsent>
      )}
    </nav>
  );
};

export default Header;
