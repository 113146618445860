import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'

import './scss/main.scss'

import NoMatchPage from './pages/404'
import HomePage from './pages/home'
import ProjectPage from './pages/projects'
import SurvivorPage from './pages/survivor'
import SurvivorLandingPage from './pages/survivor_landing'
import NascarPage from './pages/nascar'
import ESLPage from './pages/esl'
import DigitalPage from './pages/digital'
import TeamPage from './pages/team'
import JoinPage from './pages/join'
import ContactPage from './pages/contact'
import NewsPage from './pages/news'
import TermsPage from './pages/terms'
import PrivacyPage from './pages/privacy'
import ScrollToTop from './components/scroll-top'

const pixelID = '53b5ee00-ea3b-0138-7e89-06b4c2516bae'

function App() {
  useEffect(() => {
    ReactPixel.init(pixelID)
  })

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={() => <HomePage />} />
        <Route exact path="/projects" component={() => <ProjectPage />} />
        <Route exact path="/survivor" component={() => <SurvivorPage />} />
        <Route exact path="/survivornotify" component={() => <SurvivorLandingPage />} />
        <Route exact path="/nascar" component={() => <NascarPage />} />
        <Route exact path="/esl" component={() => <ESLPage />} />
        <Route exact path="/digital" component={() => <DigitalPage />} />
        <Route exact path="/team" component={() => <TeamPage />} />
        <Route exact path="/jobs" component={() => <JoinPage />} />
        <Route exact path="/news" component={() => <NewsPage />} />
        <Route exact path="/terms" component={() => <TermsPage />} />
        <Route exact path="/privacy" component={() => <PrivacyPage />} />
        <Route exact path="/contact" component={() => <ContactPage />} />
        <Route component={NoMatchPage} />
      </Switch>
    </Router>
  )
}

export default App
