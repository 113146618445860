import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore'

import Icon from '../components/icon'

import { db } from '../firebase'

const validate = Yup.object({
  firstName: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
  lastName: Yup.string().max(20, 'Must be 20 characters or less').required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  confirmEmail: Yup.string()
    .when('email', {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('email')], 'The email and confirm email do not match'),
    })
    .required('Required'),
})

const SurvivorPreRegForm = () => {
  const [formState, setFormState] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
    },
    validationSchema: validate,
    onSubmit: async values => {
      let formVals = { ...values }
      delete formVals.confirmEmail
      setFormSubmitting(true)
      await createPreRegDoc(values)
      formik.resetForm()
      setFormState(true)
    },
  })

  const createPreRegDoc = async values => {
    const docRef = await doc(db, 'sur-40-pre-reg', values.email)
    const docSnap = await getDoc(docRef)
    if (!docSnap.exists()) {
      await setDoc(doc(db, 'sur-40-pre-reg', values.email), { created_at: Timestamp.now(), ...values })
    }
  }

  if (formState) {
    return <div>Thank you. We will contact you soon.</div>
  }

  return (
    <form onSubmit={formik.handleSubmit} name="sur40form">
      <section className="modal-card-body">
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              {formik.touched.firstName && formik.errors.firstName ? (
                <label className="label">
                  First Name <Icon icon={['fas', 'exclamation-triangle']} className="has-text-danger fa-sm" />
                  {formik.errors.firstName}
                </label>
              ) : (
                <label className="label">
                  First Name <Icon icon={['fas', 'asterisk']} className="has-text-danger fa-sm" />
                </label>
              )}
              <div className="control">
                <input name="firstName" className="input" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.firstName} />
              </div>
            </div>
          </div>
          <div className="column is-half">
            <div className="field">
              {formik.touched.lastName && formik.errors.lastName ? (
                <label className="label">
                  Last Name <Icon icon={['fas', 'exclamation-triangle']} className="has-text-danger fa-sm" /> {formik.errors.lastName}
                </label>
              ) : (
                <label className="label">
                  Last Name <Icon icon={['fas', 'asterisk']} className="has-text-danger fa-sm" />
                </label>
              )}
              <div className="control">
                <input name="lastName" className="input" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.lastName} />
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          {formik.touched.email && formik.errors.email ? (
            <label className="label">
              Email <Icon icon={['fas', 'exclamation-triangle']} className="has-text-danger fa-sm" /> {formik.errors.email}
            </label>
          ) : (
            <label className="label">
              Email <Icon icon={['fas', 'asterisk']} className="has-text-danger fa-sm" />
            </label>
          )}
          <div className="control">
            <input name="email" className="input" type="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
          </div>
        </div>

        <div className="field">
          {formik.touched.confirmEmail && formik.errors.confirmEmail ? (
            <label className="label">
              Confirm Email <Icon icon={['fas', 'exclamation-triangle']} className="has-text-danger fa-sm" /> {formik.errors.confirmEmail}
            </label>
          ) : (
            <label className="label">
              Confirm Email <Icon icon={['fas', 'asterisk']} className="has-text-danger fa-sm" />
            </label>
          )}
          <div className="control">
            <input name="confirmEmail" className="input" type="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.confirmEmail} />
          </div>
        </div>

        <div className="field">
          <button className="button is-success" type="submit" disabled={formSubmitting}>
            Submit
          </button>
          <a href="https://survivor.zinioapps.com/shop" rel="noopener noreferrer" target="_blank" className="is-primary is-pulled-right">
            Get the digital version
          </a>
        </div>
      </section>
    </form>
  )
}

export default SurvivorPreRegForm
