import React from "react";
import { Link } from "react-router-dom";

import Layout from "../components/layout/public";
import Banner from "../components/banner";
import HomeTransImage from "../images/digital-print-header.jpg";
import ConnectedImg from "../images/Depositphotos_16348215_l-2015.jpg";
import SponsershipImage from "../images/Virtual-Event-Sponsorship-740x352.jpg";
import HandshakeImage from "../images/handshake-2.png";
import BrandStoryTelling from "../images/b2b-brand-storytelling-img-scaled.jpg";
import LightBulbImage from "../images/lightbulbs-1.png";
import Icon from "../components/icon";

const messages = [];
messages.push(
  {
    title: "Finesse",
    message:
      "(noun) skill in handling a difficult or highly sensitive situation; extreme delicacy or subtlety in action, performance, skill.",
  },
  {
    title: "Unique",
    message: "(adjective) having no like or equal; unparalleled; incomparable.",
  }
);
const HomePage = () => (
  <Layout showHeader={true}>
    <section className="hero is-fullheight banner-background-img">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-3 has-text-grey">We are Finesse Unique...</h1>
          <h2 className="subtitle">
            <br />
            <Banner messages={messages} />
            <a href="#print1">
              What we do <Icon icon="arrow-right" />
            </a>
          </h2>
        </div>
      </div>
    </section>

    <section className="hero is-medium is-black">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-3 has-text-grey">What we do...</h1>
          <h2 className="subtitle">
            <div className="columns" id="print2">
              <div className="column is-half">
                <figure>
                  <img src={ConnectedImg} alt="ott" />
                </figure>
              </div>
              <div className="column">
                <span className="is-size-1">OTT Advertising + Geofencing</span>
                <p>
                  Our Geo fencing and addressable (household) geofencing
                  technology combined with OTT Advertising can give advertisers
                  the competitive edge they’ve been looking for and that none of
                  their competitors are using. See mobile location based
                  advertising in action
                </p>
              </div>
            </div>
            <br />
            <div className="columns">
              <div className="column is-half" id="print1">
                <span className="is-size-1">
                  Digital and Print Publications
                </span>
                <p>
                  Delivering your message through traditional and modern
                  publications with content that evokes passion. We design and
                  publish event, annual, commemorative, and keepsake
                  publications in both digital and traditional print formats. We
                  have been fortunate and honored to have worked with properties
                  such as the NFL, MLB, PGA, LPGA, NCAA Bowls, NCAA March
                  Madness, NASCAR, CMA’s, The Grammy’s, American Music Award and
                  CBS.
                </p>
              </div>
              <div className="column">
                <figure>
                  <img src={HomeTransImage} alt="home page transition" />
                </figure>
              </div>
            </div>
            <br />
            <div className="columns" id="print2">
              <div className="column is-half">
                <figure>
                  <img src={SponsershipImage} alt="sponsership" />
                </figure>
              </div>
              <div className="column">
                <span className="is-size-1">Sponsorship Marketing</span>
                <p>
                  This is more than generating impressions; this is about making
                  lasting and memorable impressions. Collaboratively we have
                  decades of marketing experience to create an integrated
                  sponsorship marketing campaign guaranteed to deliver results
                  that best fit your strategic objectives. We work with our
                  corporate partners to map out the most valuable brand-to-fan
                  connections. A win-win all the around.
                </p>
              </div>
            </div>
            <br />
            <div className="columns" id="print2">
              <div className="column">
                <span className="is-size-1">Brand Storytelling</span>
                <p>
                  Creating a narrative to connect your brand to customers. Brand
                  storytelling incorporates the facts and emotions that your
                  brand evokes. We wrap your message into a magical story that
                  transports people, simplifies information, provokes an
                  emotional response, and delivers a happily ever after.
                </p>
              </div>
              <div className="column is-half">
                <figure>
                  <img src={BrandStoryTelling} alt="brand styory telling" />
                </figure>
              </div>
            </div>
            <br />
            <div className="columns">
              <div className="column">
                <figure>
                  <img src={LightBulbImage} alt="light bulb" />
                </figure>
              </div>
              <div className="column is-half" id="print3">
                <span className="is-size-1">
                  Corporate Entertainment & Hospitality
                </span>
                <p>
                  Inspiring loyalty, cementing current realtionships, and
                  securing new business in a captitvating environment. We are
                  devoted to fun with our team and our clients. It’s the BIG
                  things: our team will roll out the red carpet, not only by
                  offering premier events with VIP hospitality packages but also
                  by utilizing the on-site relationships for corporate clients
                  to promote their brands and close sales opportunities. And
                  it’s the small things: you’ll have to wait and see.
                </p>
              </div>
            </div>
            <br />
            <div className="columns" id="print2">
              <div className="column">
                <span className="is-size-1">Contract Negotiation</span>
                <p>
                  Effective negotiation is just a conversation, and we like to
                  talk to get the value our clients deserve. We focus on
                  creating and negotiating custom contracts to protect our
                  clients’ interests, whether they are an athlete, a team, a
                  property, or an event. Our team uses comprehensive
                  preparation, research, and planning to generate maximum
                  compensation for our clients.
                </p>
              </div>
              <div className="column is-half">
                <figure>
                  <img src={HandshakeImage} alt="contract negotiation" />
                </figure>
              </div>
            </div>
          </h2>
        </div>
      </div>
    </section>

    <section className="hero is-medium is-light">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-3 has-text-grey">Our works…</h1>
          <h2 className="subtitle">
            <br />
            <p className="is-size-1">Explore new strategies</p>
            <p className="is-size-4">
              Thinking beyond limits means having an open mind to all
              possibilities that create impactful work for our clients. With no
              boundaries, everyone wins.{" "}
            </p>
            <br />
            <div>
              <Link to="/projects" className="is-link is-size-3">
                Check out some of our amazing projects.{" "}
                <Icon icon="arrow-right" />
              </Link>
            </div>
          </h2>
        </div>
      </div>
    </section>
  </Layout>
);

export default HomePage;
