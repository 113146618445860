import React from "react";
import { withRouter } from "react-router";

import Layout from "../components/layout/public";

const NewsPage = () => (
  <Layout showHeader={true}>
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-size-1">Coming Soon</h1>
          <h2 className="subtitle">Just for you</h2>
        </div>
      </div>
    </section>
  </Layout>
);

export default withRouter(NewsPage);
