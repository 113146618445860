import React from 'react'

import Layout from '../components/layout/public'
import OppsImage from '../images/404.jpg'

const NoMatchPage = ({ location }) => (
    <Layout showHeader={true}>
    <div className="container has-text-centered">
      <div className="columns is-vcentered">
        <div className="column is-5">
          <figure className="image">
            <img src={OppsImage} alt="Oops" />
          </figure>
        </div>
        <div className="column is-6 is-offset-1">
          <h1 className="title is-2">
            <i className="fa fa-bomb fa-lg" />
          </h1>
          <h1 className="title is-2">404</h1>
          <h2 className="subtitle is-4">No match for <code>{location.pathname}</code></h2>
        </div>
      </div>
    </div>
  </Layout>
)

export default NoMatchPage