import React, { useState } from "react";
import { withRouter } from "react-router";

import Layout from "../components/layout/public";
import Icon from "../components/icon";
import BosImage from "../images/survivorbos-1024x716.jpg";
import SurvivorDownloadImg from "../images/SurvivorDownload.png";
import SurvivorMobileHeaderImage from "../images/Survivor-header-750X470.jpg";

const SurvivorPage = () => {
  const [modalCss, setModalCss] = useState("modal");
  const [pdfName, setPdfName] = useState();
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const setModalCssClass = (val, pdfName) => {
    setModalCss(val);
    setPdfName(pdfName);
    setPassword("");
    setError("");
  };

  const getMediaName = (val) => {
    switch (val) {
      case "sur-all":
        return "Survivor 40 Marketing Kit";
      case "sur-media":
        return "Survivor 40 Media Kit";
      case "sur-editorial":
        return "Survivor 40 Editorial Content";
      case "sur-mech":
        return "Survivor 40 Mechanical Specs";
      default:
        return "";
    }
  };

  const downloadMedia = () => {
    if (password && password.length > 0) {
      setIsLoading(true);
      fetch(`https://dblbee-server.com/downloadKit?b=${pdfName}&p=${password}`)
        .then((response) => response.blob())
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `${getMediaName(pdfName)}.zip`;
          a.click();
          setModalCssClass("modal", "");
          setIsLoading(false);
        });
    } else {
      setError("Password is required");
    }
  };

  return (
    <Layout showHeader={true}>
      <section className="hero is-fullheight survivor-banner-background-img is-hidden-touch">
        <div className="hero-body">
          <div className="container">
            <h2 className="subtitle">
              {" "}
              <div className="columns">
                <div className="column" id="print1"></div>
              </div>
            </h2>
          </div>
        </div>
      </section>
      <section className="hero is-medium is-hidden-desktop">
        <div className="hero-body">
          <div className="container">
            <h2 className="subtitle">
              {" "}
              <div className="columns">
                <div className="column" id="print1">
                  <img src={SurvivorMobileHeaderImage} alt="header" />
                </div>
              </div>
            </h2>
          </div>
        </div>
      </section>

      <section className="hero is-medium">
        <div className="hero-body">
          <div className="container">
            <h2 className="subtitle">
              <div className="columns">
                <div className="column is-half" id="print1">
                  <span className="is-size-2">
                    Celebrating 40 Seasons of Survivor
                  </span>
                  <p>
                    For 20 years, Survivor has set the standard of excellence
                    for reality competition shows. To commemorate this
                    astounding legacy, CBS Consumer Products has selected
                    Finesse Unique to produce 40 Seasons of Survivor: The
                    Official Collector’s Edition, a perfect-bound keepsake book
                    and digital publication containing more than 220 pages of
                    photos, exclusive interviews, insight, and behind-the-scenes
                    details. Host and executive producer Jeff Probst says, “Fans
                    are going to love it!”
                  </p>
                </div>
                <div className="column">
                  <figure>
                    <img src={BosImage} alt="survivor" />
                  </figure>
                </div>
              </div>
            </h2>
          </div>
        </div>
      </section>

      <section className="hero is-medium is-light">
        <div className="hero-body">
          <div className="container">
            <h2 className="subtitle">
              <div className="columns">
                <div className="column is-11 is-offset-1">
                  <a
                    href="/contact#contact"
                  >
                    <div className="card">
                      <div className="card-image">
                        <figure className="image">
                          <img src={SurvivorDownloadImg} alt="marketing kit" />
                        </figure>
                      </div>
                      <div className="card-content">
                        <div className="media">
                          <div className="media-content">
                            <p className="subtitle is-size-6">
                              
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </h2>
          </div>
        </div>
      </section>

      <div className={modalCss}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Download {getMediaName(pdfName)}</p>
            <button
              className="delete"
              aria-label="close"
              type="button"
              onClick={() => setModalCssClass("modal", "")}
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">
                Password{" "}
                <Icon
                  icon={["fas", "asterisk"]}
                  className="has-text-danger fa-sm"
                />
              </label>
              {error && (
                <label className="label">
                  <Icon
                    icon={["fas", "exclamation-triangle"]}
                    className="has-text-danger fa-sm"
                  />{" "}
                  {error}
                </label>
              )}
              <div className="control">
                <input
                  className="input"
                  type="password"
                  ref={(input) => input && input.focus()}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            {!isLoading && (
              <button
                className="button is-success"
                type="button"
                onClick={downloadMedia}
              >
                Download
              </button>
            )}
            {isLoading && (
              <label className="label mr-2">
                <Icon icon={["fas", "spinner"]} className="fa-sm fa-pulse" />{" "}
                Downloading...
              </label>
            )}
            <button
              className="button"
              type="button"
              onClick={() => setModalCssClass("modal", "")}
            >
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(SurvivorPage);
