import React, { useState } from "react";

import Icon from "./icon";

const FadeCard = ({ title, children }) => {
  const [isShowing, setIsShowing] = useState(false);
  const [fadeCssClass, setFadeCssClass] = useState("remove-me");
  const setIsShowingCss = (val) => {
    setIsShowing(val);
    setFadeCssClass(
      val ? "message is-medium fade-in" : "message is-medium fade-out-remove"
    );
  };
  return (
    <div className="container">
      <div className="is-size-5 profile-card" onClick={() => setIsShowingCss(!isShowing)}>
        <p>
          {title}{" "}
          {!isShowing && <Icon className="is-pulled-right" icon={["fas", "arrow-alt-circle-right"]} />}
          {isShowing && <Icon className="is-pulled-right" icon={["fas", "arrow-alt-circle-down"]} />}
        </p>
      </div>
      <article className={fadeCssClass}>
        <div className="message-body">{children}</div>
      </article>
      <hr />
    </div>
  );
};

export default FadeCard;
