import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Icon from '../components/icon'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validate = Yup.object({
  firstName: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
  lastName: Yup.string().max(20, 'Must be 20 characters or less').required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required'),
  website: Yup.string().url('Must be a valid url'),
})

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactUs = ({ cancelCallback }) => {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      companyName: '',
      website: '',
      assist: '',
    },
    validationSchema: validate,
    onSubmit: values => {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'ott-form', ...values }),
      })
        .then(() => {
          if (cancelCallback) {
            cancelCallback()
          }
          formik.resetForm()
        })
        .catch(error => alert(error))
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} name="ott-form">
      <input type="hidden" name="form-name" value="ott-form" />
      <section className="modal-card-body">
        <div className="columns">
          <div className="column is-half">
            <div className="field">
              {formik.touched.firstName && formik.errors.firstName ? (
                <label className="label">
                  First Name <Icon icon={['fas', 'exclamation-triangle']} className="has-text-danger fa-sm" />
                  {formik.errors.firstName}
                </label>
              ) : (
                <label className="label">
                  First Name <Icon icon={['fas', 'asterisk']} className="has-text-danger fa-sm" />
                </label>
              )}
              <div className="control">
                <input name="firstName" className="input" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.firstName} />
              </div>
            </div>
          </div>
          <div className="column is-half">
            <div className="field">
              {formik.touched.lastName && formik.errors.lastName ? (
                <label className="label">
                  Last Name <Icon icon={['fas', 'exclamation-triangle']} className="has-text-danger fa-sm" /> {formik.errors.lastName}
                </label>
              ) : (
                <label className="label">
                  Last Name <Icon icon={['fas', 'asterisk']} className="has-text-danger fa-sm" />
                </label>
              )}
              <div className="control">
                <input name="lastName" className="input" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.lastName} />
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          {formik.touched.email && formik.errors.email ? (
            <label className="label">
              Email <Icon icon={['fas', 'exclamation-triangle']} className="has-text-danger fa-sm" /> {formik.errors.email}
            </label>
          ) : (
            <label className="label">
              Email <Icon icon={['fas', 'asterisk']} className="has-text-danger fa-sm" />
            </label>
          )}
          <div className="control">
            <input name="email" className="input" type="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
          </div>
        </div>

        <div className="field">
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <label className="label">
              Phone Number <Icon icon={['fas', 'exclamation-triangle']} className="has-text-danger fa-sm" /> {formik.errors.phoneNumber}
            </label>
          ) : (
            <label className="label">
              Phone Number <Icon icon={['fas', 'asterisk']} className="has-text-danger fa-sm" />
            </label>
          )}
          <div className="control">
            <input name="phoneNumber" className="input" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phoneNumber} />
          </div>
        </div>

        <div className="field">
          <label className="label">Company Name</label>
          <div className="control">
            <input name="companyName" className="input" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.companyName} />
          </div>
        </div>

        <div className="field">
          {formik.touched.website && formik.errors.website ? (
            <label className="label">
              Website <Icon icon={['fas', 'exclamation-triangle']} className="has-text-danger fa-sm" />
              {formik.errors.website}
            </label>
          ) : (
            <label className="label">Website</label>
          )}
          <div className="control">
            <input name="website" className="input" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.website} />
          </div>
        </div>

        <div className="field">
          <label className="label">How can we assist? </label>
          <div className="control">
            <input name="assist" className="input" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.assist} />
          </div>
        </div>
        <div className="field">
          <button className="button is-success" type="submit">
            Submit
          </button>
          {cancelCallback && (
            <button className="button" type="button" onClick={() => cancelCallback}>
              Cancel
            </button>
          )}
        </div>
      </section>
    </form>
  )
}

export default ContactUs
