import React from "react";
import { withRouter } from "react-router";

import Layout from "../components/layout/public";
import FadeCard from "../components/fade-card";
import Icon from "../components/icon";

const JoinPage = () => (
  <Layout showHeader={true}>
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-size-1">Join Finesse Unique</h1>
          <h2 className="subtitle">
            Why choose FU? It’s simple. People work for people. We have some
            awesome people. We are passionate about what we do and seek out
            people with that same passion. We provide freedom to our employees
            to make decisions about things that are important to them. We
            encourage our team to challenge us and offer ideas on how we can
            improve and implement better ways of getting things done. We believe
            in transparency and want our team to feel included. Awesome people
            do awesome things. Having fun, laughing and treating our team to
            special events, and offering special bonuses and spiffs helps
            motivate and retain our FU family.
          </h2>
          <a
            className="is-size-5 has-text-success has-text-weight-bold"
            href="#jobs"
          >
            Current Job Openings <Icon icon="arrow-right" />
          </a>
        </div>
      </div>
    </section>

    <section className="hero is-medium is-black">
      <div className="hero-body" id="jobs">
        <FadeCard title="ADVERTISING SALES FOR PRINT AND DIGITAL">
          <div className="container">
            <p>
              <strong>Job Summary</strong>
            </p>
            <p>
              Finesse Unique, is a marketing and advertising agency based in
              Tampa, FL with many connections and opportunities in the sports
              world with regards to sponsorship and endorsements. We are
              currently working on publishing two significant projects, both in
              traditional print and modern digital format, both are expected to
              launch this fall. One is with a highly coveted national network
              and their Emmy-Award winning show, and the second with a global
              e-sports conglomerate. We are seeking experienced advertising
              sales professionals to join our team of revenue hunters. A
              tremendous opportunity for a seasoned advertising sales
              professional to walk in on day one with a substantial legacy book
              of business.
            </p>
            <p>
              <strong>Responsibilities and Duties</strong>
            </p>
            <p>
              The primary objective is to acquire new advertising customers for
              our publication’s print and digital platforms. Secondarily, the
              advertising sales professional will is expected to manage an
              active book of advertising customers to reach established monthly
              budgets. The advertising sales professional will also have the
              opportunity to cross-sell into Finesse Unique’s other publishing
              titles, and projects once confirmed and announced.
            </p>
            <p>
              <strong>Qualifications and Skills</strong>
            </p>
            <ul>
              <li>Motivated to make money</li>
              <li>Competitive</li>
              <li>Excellent communication</li>
              <li>High energy</li>
              <li>Works well under pressure</li>
              <li>Multi-tasking</li>
              <li>
                Technical competencies including typing and familiar with
                outlook, excel and CRM’s
              </li>
              <li>Minimum two (2) years of experience in advertising sales</li>
              <li>Strong understanding of digital media</li>
              <li>
                Familiar with building customer relationships and cross-selling
                techniques
              </li>
            </ul>
            <p>
              <strong>Benefits</strong>
            </p>
            <p>
              Benefits available include access to using cutting edge technology
              to identify decision-makers and create email marketing campaigns.
            </p>
            <p>
              A fun and creative workspace of others, we are also hungry for
              money. There is an opportunity to work remotely after a probation
              period depending on experience.
            </p>
            <p>Job Type: Full-time and/or Part-time</p>
            <p>
              Pay: Draw vs Commission available for qualified candidates. We are
              open to negotiation for the TOP 20% that does 80% of the sales.
              Bonus opportunities and incentives are always plentiful.
              Additional perks such as tickets to games, overnight trips, and
              team outings also available to reward those who are grinding.
            </p>
            <p>Experience:</p>
            <ul>
              <li>Sales: 2 years (Required)</li>
            </ul>
            <p>
              Be prepared to share sales experience, examples of building
              relationships, and closing deals as well as strategies you
              implement and the homework you do to be ready to go after new
              business.
            </p>
          </div>
        </FadeCard>
        <FadeCard title="EXECUTIVE ADMINISTRATIVE ASSISTANT">
          <div>
            <p>
              Join Finesse Unique, a new thriving media company based in Tampa,
              FL. This position can be 50% remote depending on experience.
              <br />
              <br />
              <strong>Job Description</strong>
              <br />
              The Executive Administrative Assistant plays a vital role in
              supporting the leadership team and performs key administrative
              tasks within the organization. The ability to maintain complex
              schedules, closely guard confidential information, and manage
              administrative support, are essential in this position. The ideal
              candidate for this job is an excellent communicator, a skilled
              task master and is highly organized. Assuring a steady completion
              of workload in a timely manner is key to success in this position.
              <br />
              <br />
              We are proud to offer the following&nbsp;
              <strong>unique perks</strong> to our team members:
            </p>
            <ul>
              <li>
                50% remote work environment gives you the flexibility to enjoy
                your life and time
              </li>
              <li>No weekends!</li>
              <li>
                State-of-the-art Employee Self Service portal giving you
                real-time access to your information!
              </li>
            </ul>
            <p>
              <strong>Requirements:</strong>
            </p>
            <ul>
              <li>
                Minimum of 2 years relevant experience in an administrative or
                executive assistant role
              </li>
              <li>
                Proficiency in Google and Microsoft Office applications required
              </li>
              <li>
                Ability to analyze and revise operating practices to improve
                efficiency
              </li>
              <li>
                Detail oriented and comfortable working in a fast-paced
                environment
              </li>
              <li>Exceptional communication skills</li>
              <li>
                Superior organization skills and dedication to completing
                projects in a timely manner
              </li>
              <li>
                Ability to visualize and communicate the steps needed to
                complete a project
              </li>
              <li>High loyalty and dedication</li>
              <li>Focus on quality in all aspects of the job</li>
              <li>High level of self-confidence and independence</li>
            </ul>
            <p>
              Please email: qualifying resume and a brief description of you
              to&nbsp;
              <a href="mailto:amber@finesseunique.com">
                <strong>amber@finesseunique.com</strong>
              </a>
            </p>
          </div>
        </FadeCard>
        <FadeCard title="SOCIAL MEDIA MANAGER MAGICIAN">
          <div>
            <p>
              The Finesse Unique Social Manager is responsible for planning
              social media calendars, creating social media posts, and assisting
              the social media team with brainstorming campaigns.
              <br />
              <br />
              To be successful you must have excellent knowledge of various
              social media platforms. A dynamic Social Media Intern combines
              creative campaign ideas with analytical skills to create
              successful posts.
              <br />
              <br />
              Social Media Intern Responsibilities:
            </p>
            <ul>
              <li>
                Manage the social media calendar.
                <br />
                Brainstorm campaign ideas.
              </li>
              <li>
                Post on various social media platforms such as Facebook,
                Instagram, Twitter, and LinkedIn.
              </li>
              <li>Analyze analytics to gauge the success of campaigns.</li>
            </ul>
            <p>Social Media Requirements:</p>
            <ul>
              <li>Excellent knowledge of social media platforms.</li>
              <li>Knowledge of analytical tools.</li>
              <li>Creative mindset.</li>
              <li>Ability to multitask.</li>
              <li>Ability to work in a team.</li>
              <li>Witty sense of humor.</li>
            </ul>
            <p>
              Our main office is located in Tarpon Springs, FL and we have a
              satellite office in Ybor City, FL. This position can be remote;
              however, we would like to meet at least once a week to touch base
              and plan ahead in person or via a Zoom call.
              <br />
              <br />
              Please email: 1. qualifying resume and a brief description of you
              to&nbsp;
              <a href="mailto:amber@finesseunique.com">
                <strong>amber@finesseunique.com</strong>
              </a>
            </p>
          </div>
        </FadeCard>
        <FadeCard title="WEBSITE DESIGNER, CREATOR AND E-COMMERCE MASTERMIND">
          <div>
            <p>
              Are you passionate about website design, development, e-commerce,
              and maintenance.
            </p>
            <p>
              We are looking for a detail oriented Web Designer and Developer
              with some digital graphic design experience.
            </p>
            <p>
              The candidate will hopefully have some experience as a Web
              Developer for both open source and shopping cart web applications
              for e-commerce solutions.
            </p>
            <p>As part of the Finesse Unique (FU) team we hope you will:</p>
            <ul>
              <li>have a great sense of humor (fingers crossed)</li>
              <li>be able to multi task</li>
              <li>be resilient</li>
              <li>be relentless</li>
              <li>be open and honest</li>
              <li>be YOU!</li>
            </ul>
            <p>Our ideal candidate will be able to:</p>
            <ul>
              <li>Design, create, and modify website and e-commerce shop</li>
              <li>
                Convert written, graphic, audio, and video components to
                compatible web formats
              </li>
              <li>
                Analyze user&nbsp;needs to implement web site content, graphics,
                performance, and capacity
              </li>
              <li>Integrate&nbsp;web sites with other computer applications</li>
              <li>Keep up-to-date on web developments and trends</li>
            </ul>
            <p>
              Our main office is located in Tarpon Springs, FL and we have a
              satellite office in Ybor City, FL. This position can be remote;
              however, we would like to meet at least once a week to touch base
              and plan ahead either in person or via Zoom.
            </p>
            <p>
              Please email: 1. qualifying resume and a brief description of you
              to&nbsp;
              <a href="mailto:amber@finesseunique.com">
                <strong>amber@finesseunique.com</strong>
              </a>
            </p>
          </div>
        </FadeCard>
      </div>
    </section>
  </Layout>
);

export default withRouter(JoinPage);
