import React from 'react'
import { Link } from "react-router-dom";

const MenuItem = props => {
  let onClickHandler = props.onClick ? props.onClick : e => e.preventDefault()

  return (
    <Link to="#void" onClick={onClickHandler} aria-current="page" {...props}>
      {props.children}
    </Link>
  )
}

export default MenuItem
